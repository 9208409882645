<template>
	<div class="app-container">
		<el-card>
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空
				</el-button>
				<el-button style="float: right;" @click="searchTable()" type="primary" size="small"
					icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px;">
				<el-form :inline="true" :model="searchForm" ref="searchForm" size="small" label-width="100px">
					<el-form-item label="父分类：" prop="value1">
						<el-select v-model="searchForm.value1" clearable placeholder="请选择"
							@change="changeSelectF(searchForm.value1, 'options2', '', 'searchForm')">
							<el-option v-for="item in options1" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="子分类：" prop="value2">
						<el-select v-model="searchForm.value2" clearable placeholder="请选择">
							<el-option v-for="item in options2" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="题型：" prop="value3">
						<el-select v-model="searchForm.value3" clearable placeholder="请选择">
							<el-option v-for="item in options3" :key="item.value" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="关键字：" prop="key">
						<el-input v-model="searchForm.key" placeholder="请输入关键字"></el-input>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<div class="wrap" v-loading="wrapLoading">
			<el-card class="elCard" v-for="(items, indexs) in totalArr" :key="indexs">
				<el-header class="elHeader" style="height: auto;">试卷题目<i class="el-icon-close domRight iconIright"
						@click="deleteTopic(indexs)"></i><i
						:class="items.activeNames.length > 0 ? 'el-icon-arrow-up domRight' : 'el-icon-arrow-down domRight'"
						@click="unfold(indexs)"></i></el-header>
				<el-form :model="items.topicForm" :rules="rules" ref="topicForm" size="small" label-width="110px">
					<el-collapse accordion v-model="items.activeNames">
						<el-collapse-item disabled name="1">
							<template slot="title">
								<el-form-item label="题目：" prop="title">
									<el-input v-model="items.topicForm.title" placeholder="请输入题目"
										:disabled="items.edit"></el-input>
								</el-form-item>
								<el-form-item class="optionsBox" label="分类：" required>
									<el-col :span="11">
										<el-form-item prop="parentCategoryId">
											<el-select v-model="items.topicForm.parentCategoryId" placeholder="请选择父分类"
												:disabled="items.edit"
												@change="changeSelectF(items.topicForm.parentCategoryId, 0, indexs)">
												<el-option v-for="item in options1" :key="item.value"
													:label="item.label" :value="item.value"></el-option>
											</el-select>
										</el-form-item>
									</el-col>
									<el-col :span="2" style="height: 1px;"></el-col>
									<el-col :span="11">
										<el-form-item prop="categoryId">
											<el-select v-model="items.topicForm.categoryId" placeholder="请选择子分类"
												:disabled="items.edit">
												<el-option v-for="item in items.options2" :key="item.value"
													:label="item.label" :value="item.value"></el-option>
											</el-select>
										</el-form-item>
									</el-col>
								</el-form-item>
							</template>
							<el-form-item label="题型：" prop="typeId">
								<el-select v-model="items.topicForm.typeId" placeholder="请选择题型" :disabled="items.edit">
									<el-option v-for="item in options3" :key="item.value" :label="item.label"
										:value="item.value"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item class="optionsBox" required>
								<span slot="label">选项：</span>
								<el-radio-group v-model="items.topicForm.answer" size="small" style="width: 100%;"
									:disabled="items.edit">
									<div v-for="(item, index) in items.topicForm.detail" :key="index">
										<el-col :span="4">
											<el-radio-button class="answerBtn" :label="item.sort"></el-radio-button>
										</el-col>
										<el-col :span="20">
											<el-form-item :prop="'detail.' + index + '.title'"
												:rules="{ required: true, message: '请输入选项', trigger: 'blur'}">
												<el-input v-model="item.title" placeholder="请输入选项"
													:disabled="items.edit"></el-input>
											</el-form-item>
										</el-col>
									</div>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="答案：" prop="answer">
								<el-input v-model="items.topicForm.answer" placeholder="请输入答案" :disabled="items.edit">
								</el-input>
							</el-form-item>
							<el-form-item label="难易度：" prop="difficultLevel">
								<el-radio-group v-model="items.topicForm.difficultLevel" size="small"
									:disabled="items.edit">
									<el-radio-button label="2">难</el-radio-button>
									<el-radio-button label="1">易</el-radio-button>
								</el-radio-group>
							</el-form-item>
							<el-form-item label="解析说明：" prop="explainInfo">
								<el-input type="textarea" v-model="items.topicForm.explainInfo" placeholder="请输入解析说明"
									:disabled="items.edit"></el-input>
							</el-form-item>
						</el-collapse-item>
					</el-collapse>
					<el-form-item class="itemRight">
						<el-button type="primary" @click="bottomEdit(indexs, 'topicForm')">找回</el-button>
					</el-form-item>
				</el-form>
			</el-card>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" :current-page="page"
				layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				page: 1,
				total: 0,
				importDialog: false,
				importForm: {
					value1: '',
					value2: ''
				},
				importRules: {
					value1: [{
						required: true,
						message: '请选择父分类',
						trigger: 'change'
					}],
					value2: [{
						required: true,
						message: '请选择子分类',
						trigger: 'change'
					}],
				},
				letterArr: [],
				searchForm: {
					key: '',
					value1: '',
					value2: '',
					value3: ''
				},
				options1: [],
				options2: [],
				options3: [],
				options4: [],
				// options4: [{
				//   value: '8af4de7b65ce8dc40165d08452d1007d',
				//   label: '黄金糕4'
				// }],
				// options5: [{
				//   value: '8af4de7b65b77b560165ccf1f14e09e5',
				//   label: '黄金糕5'
				// }],
				// options6: [{
				//   value: '4028d0817cdfeecd017ce3b713200000',
				//   label: '黄金糕6'
				// }],
				totalArr: [{
					edit: true,
					activeNames: [],
					topicForm: {
						title: '',
						categoryId: '',
						typeId: '',
						detail: [{
							sort: 'A',
							title: ''
						}],
						answer: '',
						difficultLevel: '',
						explainInfo: ''
					},
					options1: [],
					options2: []
				}, {
					edit: true,
					activeNames: [],
					topicForm: {
						title: '',
						categoryId: '',
						typeId: '',
						detail: [{
							sort: 'A',
							title: ''
						}],
						answer: '',
						difficultLevel: '',
						explainInfo: ''
					},
					options2: []
				}],
				rules: {
					title: [{
						required: true,
						message: '请输入题目',
						trigger: 'blur'
					}],
					parentCategoryId: [{
						required: true,
						message: '请选择父分类',
						trigger: 'change'
					}],
					categoryId: [{
						required: true,
						message: '请选择子分类',
						trigger: 'change'
					}],
					typeId: [{
						required: true,
						message: '请选择题型',
						trigger: 'change'
					}],
					answer: [{
						required: true,
						message: '请选择答案',
						trigger: 'change'
					}],
					difficultLevel: [{
						required: true,
						message: '请选择难易度',
						trigger: 'change'
					}],
					explainInfo: [{
						required: true,
						message: '请输入解析说明',
						trigger: 'blur'
					}],
				},
			}
		},
		components: {

		},
		created() {
			let arr = [];
			for (var i = 65, j = 0; i < 91; i++, j++) {
				arr[j] = String.fromCharCode(i);
			}
			this.letterArr = arr;
			this.getList(1);
			this.getClassify(0, 'options1');
			this.getTopic();
		},
		methods: {
			getList(page) {
				this.wrapLoading = true;
				let data = {
					parentCategoryId: this.searchForm.value1,
					categoryId: this.searchForm.value2,
					typeId: this.searchForm.value3,
					title: this.searchForm.key,
					sortCol: '',
					sortType: 'asc',
					pageIndex: page,
					pageSize: 10,
					deleted: 1,
					examId: 'FaExamQuestions'
				}
				this.$comjs.ajax.getAjax('/fa/exam/questions/grid/list', data, this, res => {
					let arr = [];
					res.res.forEach(item => {
						let obj = {
							edit: true,
							activeNames: [],
							options2: [{
								value: item.categoryId,
								label: item.categoryName
							}],
							topicForm: item
						}
						arr.push(obj);
					})
					this.totalArr = arr;
					this.total = res.total;
					this.wrapLoading = false;
					// this.total = res.total;
				});
			},
			// 获取分类信息
			getClassify(id, name, index) {
				let data = {
					pId: id
				}
				this.$comjs.ajax.postAjax('/fa/exam/category/parent/list', data, this, res => {
					let arr = [];
					res.list.forEach(item => {
						let obj = {
							value: item.id,
							label: item.name
						}
						arr.push(obj);
					})
					if (name) {
						this[name] = arr;
						// if(name != 'options1') {
						// 	this[forms]['value2'] = "";
						// }
					} else {
						// if(name === 0) {
						// 	this.totalArr[index].topicForm.categoryId = "";
						// }
						this.totalArr[index].options2 = arr;
					}
				});
			},
			// 获取题型数据
			getTopic() {
				this.$comjs.ajax.postAjax('/fa/questionsType/select2', "", this, res => {
					let arr = [];
					res.list.forEach(item => {
						let obj = {
							value: item.id,
							label: item.text
						}
						arr.push(obj);
					})
					this.options3 = arr;
				});
			},
			// 查询
			searchTable() {
				// console.log(this.searchForm)
				this.page = 1;
				this.getList(this.page);
			},
			// 查询条件-父分类选择
			changeSelectF(val, name, index, forms) {
				if (name) {
					if (name != 'options1') {
						this[forms]['value2'] = "";
					}
				} else {
					if (name === 0) {
						this.totalArr[index].topicForm.categoryId = "";
					}
				}
				this.getClassify(val, name, index, forms);
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			// 删除题目
			deleteTopic(index) {
				this.$confirm('是否删除该题目？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (this.totalArr[index].topicForm.id) {
						let data = {
							questionId: this.totalArr[index].topicForm.id
						}
						this.$comjs.ajax.postAjax('/fa/exam/questions/remove', data, this, res => {
							this.getList(this.page);
							this.$message({
								message: '删除成功！',
								type: 'success'
							});
						});
					} else {
						this.totalArr.splice(0, 1);
					}
				}).catch(() => {
					this.$message({
						message: '取消删除',
						type: 'info'
					});
				});
			},
			// 右上角展开/收起
			unfold(index) {
				let bool = this.totalArr[index].activeNames.length > 0 ? true : false;
				if (bool) {
					this.totalArr[index].activeNames = [];
				} else {
					this.totalArr[index].activeNames = ['1'];
				}
			},
			// 右下角-找回
			bottomEdit(index, formName) {
				this.$confirm('是否找回该题目？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if (this.totalArr[index].topicForm.id) {
						let data = {
							questionId: this.totalArr[index].topicForm.id
						}
						this.$comjs.ajax.postAjax('/fa/exam/questions/restore', data, this, res => {
							this.getList(this.page);
							this.$message({
								message: '找回成功！',
								type: 'success'
							});
						});
					} else {
						this.totalArr.splice(0, 1);
					}
				}).catch(() => {
					this.$message({
						message: '取消找回',
						type: 'info'
					});
				});
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList(val);
			}
		},
		computed: {

		}
	}
</script>
<style scoped>
	.elCard {
		margin-bottom: 20px;
	}
	.wrap {
		margin-top: 20px;
	}
</style>
<style lang="scss">
	.app-container {
		padding: 20px;
	}

	.el-row {
		padding: 20px 0;
	}

	.elHeader {
		margin-bottom: 10px;
		padding: 0;
	}

	.domRight {
		float: right;
		font-size: 1.2em;
		cursor: pointer;
	}

	.iconIright {
		margin-left: 3px;
		color: #f55;
	}
	
	.wrap .el-form-item__label {
		text-align: left;
	}

	.wrap .el-select {
		width: 100%;
	}

	.optionsBox {
		margin-bottom: 0 !important;
	}

	.answerBtn {
		width: 100%;
	}

	.answerBtn .el-radio-button__inner {
		display: block;
		width: 90%;
	}

	.addAnsBtn {
		padding: 4px;
	}

	.itemRight {
		margin-bottom: 0 !important;
		text-align: right;
	}

	.linkBtn {
		padding: 0;
	}

	.linkBtn .el-link {
		display: block;
		padding: 10px 20px;
		color: #fff;
	}

	.linkBtn .el-link:hover {
		color: #fff;
	}

	// 折叠面板
	.wrap .el-collapse-item__header {
		display: block;
		height: auto;
		border: none;
	}

	.wrap .el-collapse-item__header .el-collapse-item__arrow {
		display: none;
	}

	.wrap .el-collapse,
	.wrap .el-collapse-item__wrap {
		border: none;
	}

	.wrap .el-collapse-item__content {
		padding-bottom: 0;
	}

	.wrap .el-collapse-item.is-disabled .el-collapse-item__header {
		cursor: auto;
	}
</style>
